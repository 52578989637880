/* html {
  overflow: hidden; 
  height: 100%;
  
} */
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sans-serif;
}

/* #id {
   height: 100%; 
} */

/* @font-face {
  font-family: 'SpaceAge';
  src: url(./fonts/space-age.ttf)  ;
} */
