
.content.details-table{
  width: 100%;
  overflow-x: visible;
}

.details-table {
  
  border-collapse: collapse; 
}

.details-table tr {
  border-bottom: 1px solid rgb(209, 207, 207);
}

.details-table th{
  width: 30%;
  font-weight: normal;
  white-space: nowrap;
}
.details-table td{
  /* min-width: 500px; */
}

.details-table th,
td {
  text-align: left;
  padding: 8px;
}

.row-details{
  width: 100%;
  background-color: #dcdfe0;
}