.topics-item{
    display: flex;
}

.topics-h{  
    white-space: nowrap;
    letter-spacing: 1px;
    font-weight: 400;
    padding-top: 3px;
}

.table-scroll{
    overflow-x: scroll;
     max-width: 100vw;
  }

  .responsive {
    width: 100%;
    height: auto;
  }

  .max-width-400{   
        max-width: 400px;     
  }

  @media screen and (max-width: 800px) {
      .left30{
          padding-left: 30px;
      }
  }

  @media screen and (max-width: 600px) {
    .left30{
        padding-left: 15px;
    }
}
