* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}



body {
  font-family: sans-serif;
  font-size: 1.3rem;
  letter-spacing: 1px;
  /* height: 100vh; */
  background-color: #36383d;
}

a {
  display: inline-block;
  padding: 3px;
  color: rgb(41, 124, 179);
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}

p {
  display: block;
  margin: 1rem 1.2rem;
  text-indent: 0;
}

ol {
  margin: 1rem 0;
  padding-left: 50px;
}

ul {
  display: block;
  list-style-type: disc;
  margin: 5px 0;
  padding-left: 50px;
}

ul.a {
  list-style-type: circle;
  margin-top: 0;
}

h4 {
  display: block;
  font-size: 1.1rem;
  margin: 1.33rem 0;
  font-weight: bold;
}

.p{
  display: block;
  margin: 1rem 1.2rem;
  text-indent: 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  /* overflow-y: hidden; */

}

.content {
  display: flex;
  flex-direction: column;
  max-width: 75ch;
  margin: 150px auto;
  float: none;
  font-size: 1.2rem;
  
  /* background-color: palevioletred; */
}

.flex-container {
  display: flex;
}



.left-container {
  display: flex;
  width: 15vw;
}

/* .right-container {
  display: flex;
  flex-direction: column;
  width: 85vw;
  padding-top: 90px;
  padding-left: 200px;
  padding-right: 300px;
  padding-bottom: 90px;
} */

.figure-container {
  background-color: rgba(53, 21, 0, 0.253);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 190px;
    width: 450px;
  /* margin: 50px 450px; */
}

.figure-body {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 15px;
  max-width: 399px;
}

.responsive{
  width: 100%;
  height: auto;
}

.documentnav{
  background-color: rgba(179, 181, 182, 0.507);
  margin-bottom: 50px;
  padding-bottom: 20px;
}

@media screen and (max-width: 1300px) {
  .content {
    padding: 0 10px;
  }
}

@media screen and (max-width: 880px) {
  .content {
    padding: 0 10px;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  
  .figure-container {
    margin: 30px 0;
    width: 390px
  }
}

@media screen and (max-width: 500px) {
  .content {
    font-size: small;
  }
  
}

@media screen and (max-width: 395px) {
  .figure-container {

    width: 300px
  }
  .figure-body {
    padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  }
}