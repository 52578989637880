nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* top: 0;
    left: 0; */
  height: 65px;
  position: fixed;
  width: 100%;
  z-index: 100000;
  /* background-color: yellow; */
  
}

.spacer{
  flex: 0;
}


.navbar-clear {
  /* background-color: rgba(64, 60, 77, 0.918); */
  background-color: rgba(220, 223, 224, 0.829);
  transition: 0.5s ease-in;
   position: relative; 
 
  /* top: 313px;  */
}

.navbar-clear li.nav-item a {
  color: black;
  text-decoration: none;
  
}

.navbar-black {
  /* display: flex; */
  /* align-items: center; */
  /* background-color: black; */
  /* transition: 2s ease-out; */
  background-color: #36383d;
}
/* .navbar-black > * {
  display: flex;
  flex: 1 100%;
} */
.fadeout{
  transition: all 0.5s ease;
}

.branding {
  /* background-color: tomato; */
  display: flex;
  /* justify-content: flex-start; */
  font-family: sans-serif;
  /* justify-content: flex-end; */
  /* align-items: flex-end; */
  /* justify-content: center; */
  align-items: center;
  text-transform: uppercase;
  /* width: 20%; */
  /* height: 55px; */
  /* z-index: 100001; */
  
}



.branding a {
  border-style: none;
}

/* .branding img {
  width: 39px;
  height: 39px;
  margin-top: 5px;
} */

.branding-name {
  font-size: 35px;
  padding-left: 5px;
  letter-spacing: 3px;
  color: rgba(252, 251, 255, 0.876);
}

.brand-link {
  text-decoration: none;
  z-index: 1111221;
}

/* .brand-link h2 {
  display: flex;
  flex-wrap: nowrap;
  background-color: transparent;
} */

.no-branding {
  display: none;
  width: 20%;
}

.nav-menu {
  /* background-color: purple; */
  display: flex;
  
  justify-content: center;
  
  

  align-items: center;
  /* font-size: 1rem; */
  font-size: 0.9rem;
  list-style-type: none;
  font-weight: 500;
  font-family: sans-serif;
  letter-spacing: 1px;
  margin: 0;
  padding: 0;
  /* padding-bottom: 5px; */
width: 70%;
  
  
}

.space-between{
  display: flex;
  justify-content: space-between;
}


li.nav-item a {
  color: white;
  text-decoration: none;
  white-space: nowrap;  
}

a::after {
  content: "";
  display: block;
  width: 0%;
  -webkit-transition: 0.3s;
  transition: 0.3s ease-out;
}

li.nav-item a:hover:not(.active):after {
  border-bottom: 5px solid rgb(61, 162, 230);
  width: 100%;
}

.nav-link:hover {
  color: white;
  font-weight: bold;
}

.active {
  font-weight: bold;
  padding-bottom: 0px;
  border-bottom: 5px solid rgb(61, 162, 230);
}

.burger {
  /* display: flex; */
  /* padding-top: 15px; */
  /* display: none; */
  position:fixed;
  /* right: 30px; */
  left: 25px;
  cursor: pointer;
  /* background-color: red; */
  /* width: 80px; */
  /* padding: 0 50px; */
  z-index: 111113;
  
}

.burger div {
  width: 30px;
  height: 3px;
  background-color: rgb(253, 253, 253);
  margin: 5px;
  transition: all 0.3s ease;
  
  
}

.nav-open {
  /* display: flex;
      justify-content: space-around;
      
      align-items: center;
      
      font-size: 18px;
      list-style-type: none;
      font-weight: 500 ;
      font-family:  sans-serif;
      letter-spacing: 3.9px;
      margin: 0; */
    background-color: green;
  font-family: sans-serif;
  list-style-type: none;
  position: fixed; 
  /* right: 0px; */
  height: 60vh;
  top: -50px;
  background-color: rgba(0, 0, 0, 0.918);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 500px;
  padding-top: 100px;
  /* transition: 2s ease-out; */
  transition: all 0.3s ease;
  transform: translateX(0);
  /* transition: transform 0.5s ease-in; */

  /* transform: translateX(0%); */
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 10.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
  
}

.nav-close{
  transition: all 0.3s ease;
  transform: translateX(-100);
}

.line1-active {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.line2-active {
  opacity: 0;
}
.line3-active {
  transform: rotate(45deg) translate(-5px, -6px);
}

.dot{
  visibility: hidden;
}

.active{
  visibility: visible;
}
.side-menu{background-color: #000000e3;
    /* box-shadow: 1px 0px 7px rgba(0,0,0,0.5); */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 399px;
    z-index: 111112;
    transform: translateX(-100%);
    transition: transform 350ms ease-out;
height: 50%;
min-height: 550px;
padding-top: 60px;
    }

    .side-menu.open{
      transform: translateX(0);
    }


.side-menu ul{
  /* height: 70%; */
  margin-top: 50px;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}
.side-menu li{
  /* margin: 0.5rem; */
  padding: 30px;

}

@media(max-width: 1233px){
  .nav-menu{
      display: none;
     
  }

  .navbar-clear {
    /* background-color: rgba(64, 60, 77, 0.918); */
    background-color:transparent;
    
     position: absolute;
     top: 0; 
   
    /* top: 313px;  */
  }
  .navbar-clear li.nav-item a {
    color: white; 
  }


}
@media(min-width: 1234px){
 
  .burger{
      display: none;
  }
  .side-menu{
    display: none;
  }
 
}

@media screen and (max-width: 400px) {
  .branding {
    padding-left: 30px;
  }
}

@media screen and (max-width: 640px) {
  .scrollmenu {
    padding-left: 300px;
  }
}

/* @media all and (min-width: 950px) {


}
@media screen and (max-width: 1450px) {
  .nav-menu {
    font-size: 0.9rem;
    letter-spacing: 2px;
  }
 
}

@media screen and (max-width: 1250px) {
  .nav-menu {
    font-size: 0.8rem;
    letter-spacing: 1.5px;
  }
  
}

@media screen and (max-width: 950px) {
  .nav-menu {
    font-family: sans-serif;
    list-style-type: none;
    position: fixed; 
    right: 0px;
    height: 60vh;
    top: -50px;
    background-color: rgba(0, 0, 0, 0.918);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 500px;
    padding-top: 100px;
    /* transition: 2s ease-out; 
    transition: all 0.3s ease;
    /* transform: translateY(-100); 
    visibility: hidden;
  }
  .navbar-clear{
    position:absolute;
    top: 0;
    background-color: transparent; 
  }
  .navbar-clear li.nav-item a {
    color: white;  
  }

  /* .nav-menu{

          position: absolute;
          right: 0px;
          height: 92vh;
          top: 8vh;
          background-color: rgba(0, 0, 0, 0.616);
          display: flex;
          flex-direction: column;
          align-items: center;       
          width: 50vw;  
          transform: translateX(100%)   ; 
          transition: transform 0.5s ease-in;
        } 

  .nav-menu li {
          opacity: 0;
        } 

   .nav-active{
          transform: translateX(100%)
        } 
  .burger {
    display: block;
    z-index: 3333333;
  }
}

@media screen and (max-width: 770px) {
  .nav-active {
    width: 100vw;
    padding-right: 50px;
  }
  .nav-menu {
    font-size: 0.6rem;
    letter-spacing: 1px;
  } */

  /* .branding {
  
    display: flex;
    font-family: sans-serif;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    width: 39%;
    height: 30px;
    
    position: absolute;
    top: 10px;
    left: 30px;
  } */
  
  /* .dot{
    visibility: visible;
    margin: 0;
    padding: 0;
  }
  .nav-date a{
    margin: 0 ;
    padding: 0;
  } */
/*   
  .branding img {
    width: 27px;
    height: 27px;
  } */
  
  /* .branding-name {
    font-size: 27px;
    padding-left: 20px;
    letter-spacing: 3px;
  } */
  /*.nav-menu-p {
     padding-left: 200px;
    padding-right: 50px; 
  }
}
*/

/* @media screen and (max-width: 670px) {
  .nav-menu-p {
    padding-left: 200px;
    padding-right: 50px;
  }
 
}

@media screen and (max-width: 570px) {
  .nav-menu-p {
    padding-left: 300px;
  }
}

@media screen and (max-width: 460px) {
  .nav-menu-p {
    padding-left: 390px;
  }
}

@media screen and (max-width: 360px) {
  .nav-menu-p {
    padding-left: 470px;
  }
} */

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.scrollmenu {

  display: flex;
  justify-content: space-around;
  align-items: center;
  /* top: 0;
    left: 0; */
  /* height: 65px; */
  position: fixed;
  width: 100%;
  z-index: 100000;



  background-color: rgb(220, 223, 224);

  overflow: auto ;
  
  white-space: nowrap;
  width: 100vw;

  margin: 63px 0;
  
  height: 60px;

  
  /* transition: 100ms ease-out */
  

  /* -webkit-animation: fadeout 0.5s; /* Safari, Chrome and Opera > 12.1 */
  /* -moz-animation: fadein 0.5s; 
  -ms-animation: fadein 10.5s; 
  -o-animation: fadein 0.5s; Opera < 12.1 */
  /* animation: fadeout 0.5s;  */
}

.nav-menu-p {
  display: flex;
  /* justify-content: space-around;  */
  align-items: center;
  font-size: 0.9rem;
  list-style-type: none;
  letter-spacing: 1.3px;  
  /* padding-bottom: 5px; */
  margin: 0 auto;
  /* width: 100vw; */
  /* background-color: saddlebrown; */
}

.nav-menu-p li{

  /* background-color: rgb(61, 162, 230); */
  margin: 0 20px;
}


