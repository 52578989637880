.footer {
  flex-shrink: 0;
  padding: 3px 0;
  border-top: 1px solid black;
  text-align: center;
  background-color: #36383d;
  color: #bac8d3;
  z-index: 3;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;

  /* max-width: 200%; */
}
