.table-cell{
    font-size: 15px;
    
    /* width: 1px; */
}

.table-cell.button{
    height: 25px;
    box-shadow: none;
        border: none;
        cursor: pointer;
        outline: none ;
        background-color: transparent;
        white-space: nowrap;
}

/* @media screen and (max-width: 816px) {
  .table-cell{
      font-size: 9px;
      width: 1px;
  }
  } */