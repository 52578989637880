
.header{

  /* font-family: 'Orbitron', sans-serif; */
position: relative;
 /* margin-top: -190px; */

 border: none;
  /* -webkit-box-shadow: 0 5px 10px -6px black;
	   -moz-box-shadow: 0 5px 10px -6px black;
          box-shadow: 0 5px 10px -6px black; */
}

.banner{
  display: flex;
  
  text-align: center;
  background: linear-gradient(to right bottom,rgba(136, 175, 187, 0.801) ,
  rgba(9, 2, 49, 0.808) ),url('banner2.jpg') no-repeat center fixed ;
  background-size: cover;
  /* height: 500px; */
height: 350px;
  
}

.nav{
  align-self: flex-end;
}

.logo-box{
  position: absolute;
  top: 39%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-box a{
  text-decoration: none;
  border-style: none;
}

.logo-icon{
  display: block;
  padding-right: 15px;
  width: 170px;
  margin: auto;
  /* margin-left: 230px; */
  animation: moveDown 1.6s ease-out;
}

.logo-main{
  display: block;
  font-size: 65px;
  color: rgba(228, 224, 241, 0.918);
  letter-spacing: 9px;
  font-family: sans-serif;
  /* font-family: 'Orbitron', sans-serif; */
  /* font-family: 'Baloo Chettan 2', cursive; */
  animation: moveRight 1.6s ease-out;
}

.logo-sub{
  display: block;
  /* font-size: 21px; */
  font-size: 21px;
  color: rgba(228, 224, 241, 0.918);
  /* color: rgb(138, 201, 243); */
  font-family:  sans-serif;
  letter-spacing: 3.3px;

  animation: moveLeft 1.6s ease-out;
}

.logo-image{
  /* width: 13%; */
  width: 50%;
  height: auto;
  padding-top: 35px; 
  margin-bottom: 0px;

}


  @keyframes moveRight{
    0%{
        opacity: 0;
        transform: translateX(-100px);
    }
    /* 80%{
        transform: translateX(10px);
    } */
    100%{
        opacity: 100%;
        transform: translate(0);
    }
}

  @keyframes moveLeft{
    0%{
      opacity: 0;
      transform: translateX(100px)
    }
    /* 80%{
      transform: translateX(-10px)
    } */
    100%{
      opacity: 1;
      transform: translate(0)
    }
  }
  
  @keyframes moveDown{
    0%{
      opacity: 0;
      transform: translateY(-50px)
    }
    /* 80%{
      transform: translateY(10px)
    } */
    100%{
      opacity: 1;
      transform: translate(0)
    }
  }

  /* @media only screen and (max-width: 1390px){

    .nav-menu{
     
    }
 

  } */

  

  @media only screen and (max-width: 1000px){

    /* .branding{
      width: auto;
      font-size: 30px;
    } */
    .logo-sub{
      font-size: 1rem;
      letter-spacing: 2px;
    }
    
  }

  /* @media only screen and (max-width: 770px){

    .nav-menu{

      top: 10vh;
     height: 80vh;

    }
  
  } */
/* 
  @media only screen and (max-width: 420px){
    .branding-name{

      display: none;
    }
    .logo-main{

      font-size: 65px;
      
    }
    
    .logo-sub{
      font-size: 17px;
      

    }
    .burger div{
      width: 35px;
      height: 4px;
      background-color: rgba(228, 224, 241, 0.918);
      margin: 4px;
    }
  }

  @media only screen and (max-width: 330px){
 
    .logo-main{

      font-size: 55px;
      
    }
    
    .logo-sub{
      font-size: 15px;
    
    }

    nav{
      height: 10vh;
    }
  } */

  /* .nav-active{
    transform: translateY(0%)
  } */